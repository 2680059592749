import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { types as api } from '@mesa-labs/mesa-api';
import { RedactedVendorApi } from '@mesa-labs/mesa-api/dist/apis/redactedVendor';

import { prepareHeaders, useApi, useVendorApi } from './api';

export const LabelToDocumentType: Record<string, api.VendorDocumentType> = {
  'Bank Statement': api.VendorBankVerificationDocumentType.BANK_STATEMENT,
  'Bank Letter': api.VendorBankVerificationDocumentType.BANK_LETTER,
  'Voided Check': api.VendorBankVerificationDocumentType.VOIDED_CHECK,
  'Voided Deposit Slip': api.VendorBankVerificationDocumentType.VOIDED_DEPOSIT_SLIP,
  'Form W-9': api.VendorBusinessVerificationDocumentType.W9,
};

export const DocumentTypeToLabel: Partial<Record<api.VendorDocumentType, string>> = {
  [api.VendorBankVerificationDocumentType.BANK_STATEMENT]: 'Bank Statement',
  [api.VendorBankVerificationDocumentType.BANK_LETTER]: 'Bank Letter',
  [api.VendorBankVerificationDocumentType.VOIDED_CHECK]: 'Voided Check',
  [api.VendorBankVerificationDocumentType.VOIDED_DEPOSIT_SLIP]: 'Voided Deposit Slip',
  [api.VendorBusinessVerificationDocumentType.W9]: 'Form W-9',
};

export const vendorApi = createApi({
  reducerPath: 'vendorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.vendorUrl,
    prepareHeaders,
  }),
  tagTypes: ['Vendor', 'VendorAccounts', 'UserAccounts', 'VendorPreference', 'VendorServiceAgreements', 'PartnerVendorServiceAgreements', 'VendorDocuments', 'Programs', 'VendorSurvey'],
  endpoints: (build) => ({
    validateOnboardingId:
      build.query<api.RedactedOnboardingResponse | undefined, { onboardingId: string }>({
        async queryFn({ onboardingId }, { dispatch }) {
          const unauthenticatedExternalVendorApi = useApi(CONFIG.api.vendorUrl, RedactedVendorApi, dispatch, false);
          const data = await unauthenticatedExternalVendorApi.getOnboarding<api.RedactedOnboardingResponse>(onboardingId);
          return { data };
        },
      }),
    registerOnboarding:
      build.query<api.RedactedOnboardingResponse | undefined, { onboardingId: string }>({
        async queryFn({ onboardingId }, { dispatch }) {
          const externalVendorApi = useVendorApi(dispatch);
          const data = await externalVendorApi.registerOnboarding<api.RedactedOnboardingResponse>(onboardingId);
          return { data };
        },
      }),
    getVendor: build.query<api.RedactedVendorAggregateResponse | undefined, { id: string }>({
      async queryFn({ id }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getVendor<api.RedactedVendorAggregateResponse>(id);
        return { data };
      },
      providesTags: (result, error, id) => [{ type: 'Vendor', ...id, ...result }],
    }),
    createUnassignedVendor: build.mutation<api.RedactedVendorAggregateResponse, api.UnassignedVendorRequest>({
      async queryFn(arg, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.createVendor<api.RedactedVendorAggregateResponse>(arg);
        return { data };
      },
    }),
    createAssignedVendor: build.mutation<api.RedactedVendorAggregateResponse, api.VendorRequest & { partnerId: number; externalVendorId: string }>({
      async queryFn({ partnerId, externalVendorId, ...body }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.createVendorByExternalIdentifiers<api.RedactedVendorAggregateResponse>(
          partnerId,
          externalVendorId,
          body,
        );
        return { data };
      },
    }),
    updateVendor: build.mutation<api.RedactedVendorResponse | undefined, api.UpdateVendorRequest & { id: string }>({
      async queryFn({ id, ...body }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.updateVendor<api.RedactedVendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: ['Vendor'],
    }),
    requestInflights: build.mutation<api.RedactedVendorResponse | undefined, api.VendorInFlightsRequest & { id: string }>({
      async queryFn({ id, ...body }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.requestInFlights<api.RedactedVendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: ['Vendor'],
    }),
    confirmVerification:
      build.mutation<api.RedactedVendorAccountResponse | undefined, api.VendorAccountVerificationRequest & { vendorId: string; vendorAccountId: string; }>({
        async queryFn({ vendorId, vendorAccountId, ...body }, { dispatch }) {
          const externalVendorApi = useVendorApi(dispatch);
          const data = await externalVendorApi.verifyVendorAccount<api.RedactedVendorAccountAggregateResponse>(vendorId, vendorAccountId, body);
          return { data };
        },
        // optimistically update the getVendor query so that the primary
        // account is considered 'succeeded' until page refresh re-triggers
        // the getVendor query
        async onQueryStarted({ vendorId, vendorAccountId }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            vendorApi.util.updateQueryData('getVendor', { id: vendorId }, (draft) => {
              const updatedAccounts = draft?.accounts?.map((account) => {
                if (account.id === vendorAccountId) {
                  return {
                    ...account,
                    verificationStatus: 'succeeded',
                  };
                }

                return account;
              });

              Object.assign(draft || {}, {
                ...draft,
                accounts: updatedAccounts,
              });
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
    createSecondaryBankAccount:
      build.mutation<api.RedactedVendorAccountResponse, api.VendorAccountRequest & { vendorId: api.UUID }>({
        async queryFn({ vendorId, ...request }, { dispatch }) {
          const externalVendorApi = useVendorApi(dispatch);
          const data = await externalVendorApi.createVendorAccount(vendorId, request);
          return { data };
        },
        invalidatesTags: ['Vendor'],
      }),
    renderLatestServiceAgreement:
      build.query<api.RenderedServiceAgreementResponse | undefined, api.RenderServiceAgreementRequest>({
        async queryFn(params: api.RenderServiceAgreementRequest, { dispatch }) {
          const externalVendorApi = useVendorApi(dispatch);
          const data = await externalVendorApi.renderLatestServiceAgreement(params);
          return { data };
        },
      }),
    renderLatestPartnerServiceAgreement:
      build.query<api.RenderedPartnerServiceAgreementResponse | undefined, api.RenderPartnerServiceAgreementRequest & { partnerId: api.Partners }>({
        async queryFn(params: api.RenderPartnerServiceAgreementRequest & { partnerId: api.Partners }, { dispatch }) {
          const { partnerId, ...requestParams } = params;
          const externalVendorApi = useVendorApi(dispatch);
          const data = await externalVendorApi.renderLatestPartnerServiceAgreement(partnerId, requestParams);
          return { data };
        },
      }),
    getVendorServiceAgreements: build.query<api.IPagedResults<api.RenderedVendorServiceAgreementResponse> | undefined, api.VendorServiceAgreementFilterParams & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...params }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getVendorServiceAgreements(vendorId, params);
        return { data };
      },
      providesTags: ['VendorServiceAgreements'],
    }),
    getVendorPartnerServiceAgreements: build.query<api.IPagedResults<api.RenderedVendorPartnerServiceAgreementResponse> | undefined, api.VendorServiceAgreementFilterParams & { vendorId: api.UUID, partnerId: api.Partners }>({
      async queryFn({ partnerId, vendorId, ...params }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getVendorPartnerServiceAgreements(partnerId, vendorId, params);
        return { data };
      },
      providesTags: ['PartnerVendorServiceAgreements'],
    }),
    signVendorServiceAgreement: build.mutation<api.RenderedVendorServiceAgreementResponse | undefined, { vendorId: api.UUID, serviceAgreementId: api.UUID, signedByName: string }>({
      async queryFn({ vendorId, serviceAgreementId, signedByName }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.signVendorServiceAgreement(vendorId, serviceAgreementId, {
          signedByName,
        });
        return { data };
      },
      invalidatesTags: ['Vendor', 'VendorServiceAgreements'],
    }),
    signVendorPartnerServiceAgreement: build.mutation<api.RenderedVendorServiceAgreementResponse | undefined, { vendorId: api.UUID, partnerId: api.Partners, serviceAgreementId: api.UUID, signedByName: string }>({
      async queryFn({
        vendorId,
        partnerId,
        serviceAgreementId,
        signedByName,
      }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.signVendorPartnerServiceAgreement(partnerId, vendorId, serviceAgreementId, {
          signedByName,
        });
        return { data };
      },
      invalidatesTags: ['Vendor', 'PartnerVendorServiceAgreements'],
    }),
    getAllUserAccounts: build.query<readonly api.RedactedUserAccountResponse[], { vendorId: api.UUID }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getAllUserAccounts<api.RedactedUserAccountResponse>(vendorId);
        return { data };
      },
      providesTags: ['UserAccounts'],
    }),
    createInitialUserAccount: build.mutation<api.RedactedUserAccountResponse, api.UserAccountRequest>({
      async queryFn({ ...body }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.createInitialUserAccount<api.RedactedUserAccountResponse>(body);
        return { data };
      },
      invalidatesTags: ['UserAccounts'],
    }),
    addUserAccountOnboardingData: build.mutation<api.RedactedUserAccountResponse, api.AddUserAccountOnboardingDataRequest & { email: string }>({
      async queryFn({ email, onboardingData }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.addUserAccountOnboardingData<api.RedactedUserAccountResponse>(email, { onboardingData });
        return { data };
      },
    }),
    createChildUserAccount: build.mutation<api.RedactedUserAccountResponse, api.UserAccountRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...body }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.createChildUserAccount<api.RedactedUserAccountResponse>(vendorId, body);
        return { data };
      },
      invalidatesTags: ['UserAccounts'],
    }),
    getUserAccount: build.query<api.RedactedUserAccountResponse | undefined, { email: string }>({
      async queryFn({ email }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getUserAccount<api.RedactedUserAccountResponse>(email);
        return { data };
      },
    }),
    registerUserAccount: build.mutation<api.RedactedUserAccountResponse | undefined, { email: string }>({
      async queryFn({ email }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.registerUserAccount<api.RedactedUserAccountResponse>(email);
        return { data };
      },
    }),
    getVendorPreference: build.query<api.UserAccountPreferencesResponse | undefined, { vendorId: string }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getVendorPreferences<api.UserAccountPreferencesResponse>(vendorId);
        return { data };
      },
      providesTags: ['VendorPreference'],
    }),
    updateVendorPreferences: build.mutation<api.UserAccountPreferencesResponse, {
      vendorId: string,
      preferences: api.UpdateVendorPreferenceRequest['preferences'],
    }>({
      async queryFn({ vendorId, preferences }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.updateVendorPreferences<api.UserAccountPreferencesResponse>(vendorId, { preferences });
        return { data };
      },
      invalidatesTags: ['VendorPreference'],
    }),
    getAllVendorDocuments: build.query<api.IPagedResults<api.MaybeRedactedVendorDocumentResponse>, api.VendorDocumentFilterParams & { vendorId: string }>({
      async queryFn({ vendorId, ...params }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getAllVendorDocuments(vendorId, params);
        return { data };
      },
      providesTags: ['VendorDocuments'],
    }),
    getPrograms: build.query<readonly api.MaybeRedactedProgramResponse[], api.GetProgramsFilterParams>({
      async queryFn(params, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getPrograms(params);
        return { data };
      },
      providesTags: ['Programs'],
    }),
    uploadVendorDocument: build.mutation<api.VendorDocumentResponse, {
      vendorId: string,
      vendorAccountId?: string,
      documentType: api.VendorDocumentType,
      file: File,
    }>({
      query({ vendorId, vendorAccountId, documentType, file }) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('documentType', documentType);

        if (vendorAccountId) {
          formData.append('vendorAccountId', vendorAccountId);
        }

        return {
          url: `vendors/${vendorId}/documents`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Vendor', 'VendorDocuments'],
    }),
    createVendorFeedback: build.mutation<api.VendorFeedbackResponse | undefined, api.CreateVendorFeedbackRequest>({
      async queryFn(params, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.createVendorFeedback(params);

        return { data };
      },
    }),
    patchUserAccount: build.mutation<api.RedactedUserAccountResponse | undefined, api.PatchUserAccountRequest & { userAccountId: string }>({
      async queryFn({ userAccountId, ...params }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.patchUserAccount(userAccountId, params);
        return { data };
      },
    }),
    patchVendorFeatures: build.mutation<api.VendorAggregateResponse | undefined, api.PatchVendorFeaturesRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...params }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.patchVendorFeatures(vendorId, params);
        return { data };
      },
      invalidatesTags: ['Vendor'],
    }),
    getVendorSurvey: build.query<api.MaybeRedactedVendorSurveyResponse | undefined, { vendorId: string, surveyIdentifier: api.VendorSurveyIdentifier }>({
      async queryFn({ vendorId, surveyIdentifier }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.getVendorSurvey(vendorId, surveyIdentifier);
        return { data };
      },
      providesTags: ['VendorSurvey'],
    }),
    storeVendorSurvey: build.mutation<api.MaybeRedactedVendorSurveyResponse | undefined, api.VendorSurveyRequest & { vendorId: api.UUID, surveyIdentifier: api.VendorSurveyIdentifier, responses: Record<string, any> | undefined }>({
      async queryFn({ vendorId, surveyIdentifier, responses }, { dispatch }) {
        const externalVendorApi = useVendorApi(dispatch);
        const data = await externalVendorApi.storeVendorSurvey(vendorId, surveyIdentifier, { responses });
        return { data };
      },
      invalidatesTags: ['VendorSurvey'],
    }),
  }),
});

export const {
  useAddUserAccountOnboardingDataMutation,
  useConfirmVerificationMutation,
  useCreateAssignedVendorMutation,
  useCreateChildUserAccountMutation,
  useCreateSecondaryBankAccountMutation,
  useCreateUnassignedVendorMutation,
  useCreateVendorFeedbackMutation,
  useGetAllUserAccountsQuery,
  useGetAllVendorDocumentsQuery,
  useGetProgramsQuery,
  useGetUserAccountQuery,
  useGetVendorPartnerServiceAgreementsQuery,
  useGetVendorPreferenceQuery,
  useGetVendorQuery,
  useGetVendorServiceAgreementsQuery,
  useGetVendorSurveyQuery,
  useLazyValidateOnboardingIdQuery,
  usePatchUserAccountMutation,
  usePatchVendorFeaturesMutation,
  useRenderLatestPartnerServiceAgreementQuery,
  useRenderLatestServiceAgreementQuery,
  useRequestInflightsMutation,
  useSignVendorPartnerServiceAgreementMutation,
  useSignVendorServiceAgreementMutation,
  useStoreVendorSurveyMutation,
  useUpdateVendorMutation,
  useUpdateVendorPreferencesMutation,
  useUploadVendorDocumentMutation,
  util,
} = vendorApi;
