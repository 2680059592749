import React, { useEffect, useState } from 'react';

type FeatureFlagConfig = {
  mca: boolean;
  instantPayout: boolean;
  testEnvironment: boolean;
  partnerServiceAgreements: boolean;
  excludeSupplementalFee: boolean;
  onDemand: boolean;
  inFlightsWelcomeModal: boolean;
  inFlightsNotification: boolean;
  feedbackUI: boolean;
  onboardingAutoGetPaidNow: boolean;
  payOnApproval: boolean;
  vendorSurvey: boolean;
  onDemandServiceAgreement: boolean;
};

const defaultFeatureFlags: FeatureFlagConfig = {
  mca: false,
  instantPayout: false,
  testEnvironment: true,
  partnerServiceAgreements: false,
  excludeSupplementalFee: false,
  onDemand: false,
  inFlightsWelcomeModal: false,
  inFlightsNotification: false,
  feedbackUI: false,
  onboardingAutoGetPaidNow: false,
  payOnApproval: false,
  vendorSurvey: false,
  onDemandServiceAgreement: false,
};

export const FeatureFlagsContext = React.createContext(defaultFeatureFlags);

type FeatureFlagsProviderProps = {
  children: React.ReactNode;
};

export function FeatureFlagsProvider({ children }: FeatureFlagsProviderProps) {
  const [features, setFeatures] = useState<FeatureFlagConfig>(defaultFeatureFlags);

  useEffect(() => {
    const featureFlagConfig = CONFIG.featureFlags;
    setFeatures(featureFlagConfig);
  }, [children]);

  return (
    <FeatureFlagsContext.Provider value={features}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext);
