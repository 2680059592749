import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

export const AuthEvents = {
  ConfirmAccountManualPaste: 'auth-confirm-account-manual-paste',
  ConfirmAccountView: 'auth-confirm-account-view',
  ForgotPasswordConfirmView: 'auth-forgot-password-confirm-view',
  ForgotPasswordSubmitView: 'auth-forgot-password-submit-view',
  ForgotPasswordView: 'auth-forgot-password-view',
  SignInView: 'auth-sign-in-view',
  SignUpView: 'auth-sign-up-view',
};

export const OnboardingEvents = {
  AutoGetPaidNowConfirm: 'onboarding-auto-get-paid-now-confirm',
  AutoGetPaidNowDeny: 'onboarding-auto-get-paid-now-deny',
  AutoGetPaidNowView: 'onboarding-auto-get-paid-now-view',
  BankInfoView: 'onboarding-bank-info-view',
  BusinessInfoView: 'onboarding-business-info-view',
  ContactSupportClick: 'onboarding-contact-support-click',
  ContactSupportCopyEmailClick: 'onboarding-contact-support-copy-email-click',
  DirectDebitAgreementView: 'onboarding-direct-debit-agreement-view',
  FinishingErrorView: 'onboarding-finishing-error-view',
  FinishingLoadingView: 'onboarding-finishing-loading-view',
  FinishingSuccessView: 'onboarding-finishing-success-view',
  JLLTermsView: 'onboarding-jll-terms-view',
  Net30PaymentScheduleConfirm: 'onboarding-net-30-payment-schedule-confirm',
  Net30PaymentScheduleView: 'onboarding-net-30-payment-schedule-view',
  OnDemandScheduleView: 'onboarding-on-demand-schedule-view',
  PayOnApprovalConfirm: 'onboarding-pay-on-approval-confirm',
  PayOnApprovalView: 'onboarding-pay-on-approval-view',
  PaymentScheduleClick: 'onboarding-payment-schedule-click',
  PaymentScheduleView: 'onboarding-payment-schedule-view',
  TermsView: 'onboarding-terms-view',
};

export const DashboardEvents = {
  AccountView: 'dashboard-account-view',
  AutoGetPaidNowClose: 'dashboard-auto-get-paid-now-close',
  AutoGetPaidNowConfirm: 'dashboard-auto-get-paid-now-confirm',
  AutoGetPaidNowDisable: 'dashboard-auto-get-paid-now-disable',
  AutoGetPaidNowLearnMoreClick: 'dashboard-auto-get-paid-now-learn-more-click',
  AutoGetPaidNowView: 'dashboard-auto-get-paid-now-view',
  BulkGetPaidNowClick: 'dashboard-bulk-get-paid-now-click',
  BulkGetPaidNowClose: 'dashboard-bulk-get-paid-now-close',
  BulkGetPaidNowConfirm: 'dashboard-bulk-get-paid-now-confirm',
  BulkGetPaidNowHover: 'dashboard-bulk-get-paid-now-hover',
  BulkGetPaidNowInterstitialConfirm: 'dashboard-bulk-get-paid-now-interstitial-confirm',
  BulkGetPaidNowView: 'dashboard-bulk-get-paid-now-view',
  BulkOnDemandClick: 'dashboard-bulk-on-demand-click',
  BulkOnDemandClose: 'dashboard-bulk-on-demand-close',
  BulkOnDemandConfirm: 'dashboard-bulk-on-demand-confirm',
  BulkOnDemandHover: 'dashboard-bulk-on-demand-hover',
  BulkOnDemandView: 'dashboard-bulk-on-demand-view',
  FeedbackUIConfirm: 'dashboard-feedback-ui-confirm',
  FeedbackUIDismiss: 'dashboard-feedback-ui-dismiss',
  FeedbackUIErrorShown: 'dashboard-feedback-ui-error-shown',
  FeedbackUIProductOrFeatureRequestShown: 'dashboard-feedback-ui-step-product-or-feature-request-shown',
  FeedbackUIRatingShown: 'dashboard-feedback-ui-step-rating-shown',
  FeedbackUISuccessShown: 'dashboard-feedback-ui-success-shown',
  GetPaidNowClick: 'dashboard-get-paid-now-click',
  GetPaidNowClose: 'dashboard-get-paid-now-close',
  GetPaidNowConfirm: 'dashboard-get-paid-now-confirm',
  GetPaidNowHover: 'dashboard-get-paid-now-hover',
  GetPaidNowInterstitialClose: 'dashboard-get-paid-now-interstitial-close',
  GetPaidNowInterstitialSingleConfirm: 'dashboard-get-paid-now-interstitial-confirm',
  InvoicesTabClick: 'dashboard-invoices-tab-click',
  MesaOnDemandClick: 'dashboard-mesa-on-demand-click',
  MesaOnDemandClose: 'dashboard-mesa-on-demand-close',
  MesaOnDemandConfirm: 'dashboard-mesa-on-demand-confirm',
  OverviewView: 'dashboard-overview-view',
  PaymentsTabClick: 'dashboard-payments-tab-click',
  RequestInflightsClick: 'dashboard-request-inflights-click',
  RequestInflightsClose: 'dashboard-request-inflights-close',
  RequestInflightsConfirm: 'dashboard-request-inflights-confirm',
  SelectiveGetPaidNowCheck: 'dashboard-selective-get-paid-now-check',
  SelectiveGetPaidNowClose: 'dashboard-selective-get-paid-now-close',
  SelectiveGetPaidNowConfirm: 'dashboard-selective-get-paid-now-confirm',
  SelectiveGetPaidNowView: 'dashboard-selective-get-paid-now-view',
  SelectiveOnDemandClose: 'dashboard-selective-on-demand-close',
  SelectiveOnDemandConfirm: 'dashboard-selective-on-demand-confirm',
  SelectiveOnDemandView: 'dashboard-selective-on-demand-view',
  SupportView: 'dashboard-support-view',
  UploadInvoiceDocumentModalClose: 'dashboard-upload-invoice-document-modal-view',
  UploadInvoiceDocumentModalConfirm: 'dashboard-upload-invoice-document-modal-confirm',
  UploadInvoiceDocumentModalView: 'dashboard-upload-invoice-document-modal-view',
  VendorSurveyClose: 'dashboard-vendor-survey-close',
  VendorSurveyStepFiveView: 'dashboard-vendor-survey-step-five-view',
  VendorSurveyStepFourView: 'dashboard-vendor-survey-step-four-view',
  VendorSurveyStepOneView: 'dashboard-vendor-survey-step-one-view',
  VendorSurveyStepThreeView: 'dashboard-vendor-survey-step-three-view',
  VendorSurveyStepTwoView: 'dashboard-vendor-survey-step-two-view',
  WelcomeModalForcedExit: 'welcome-modal-forced-exit',
  WelcomeModalHowMesaWorksView: 'welcome-modal-step-how-mesa-works-view',
  WelcomeModalInflightsView: 'welcome-modal-step-inflights-view',
  WelcomeModalInviteColleaguesView: 'welcome-modal-step-invite-colleagues-view',
  WelcomeModalWelcomeView: 'welcome-modal-step-welcome-view',
};

export const DashboardFromEmailEvents = {
  InvoiceImportSummaryBulkGetPaidNowView: 'email-invoice-import-summary-bulk-get-paid-now-view',
  PaymentSummaryBulkGetPaidNowView: 'email-payment-summary-bulk-get-paid-now-view',
  PaymentSummaryAutoGetPaidNowView: ' email-payment-summary-auto-get-paid-now-view',
  OnDemandReminderView: ' email-on-demand-reminder-view',
};

export const AllEvents = {
  ...AuthEvents,
  ...OnboardingEvents,
  ...DashboardEvents,
  ...DashboardFromEmailEvents,
};

type AllEventTypeValues = typeof AllEvents[keyof typeof AllEvents];

type GenericEventData = Record<string, unknown>;

/**
 * Uses the cognito:username attribute since analytics is collected prior to a
 * user converting to a vendor in our system.
 */
export const setAnalyticsUserId = (userId: string) => {
  try {
    mixpanel.identify(userId);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const addAnalyticsGroup = (groupKey: string, groupId: number | string) => {
  try {
    mixpanel.add_group(groupKey, groupId.toString());
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const removeAnalyticsGroup = (groupKey: string, groupId: number | string) => {
  try {
    mixpanel.remove_group(groupKey, groupId.toString());
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const trackEvent = <T = GenericEventData>(eventName: AllEventTypeValues, eventData: Partial<T> = {}) => {
  try {
    mixpanel.track(eventName, eventData);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const reset = () => {
  try {
    mixpanel.reset();
  } catch (err) {
    Sentry.captureException(err);
  }
};
