import { types as api } from '@mesa-labs/mesa-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeaders, useInvoiceApi } from './api';
import { ExportFormatType } from '../slices/invoices';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.invoiceUrl,
    prepareHeaders,
  }),
  tagTypes: ['AllTimeInvoiceTotals', 'Invoices', 'InvoiceLineItems', 'InvoiceAdjustments', 'BulkAcceleratedPayout', 'SelectiveAcceleratedPayout'],
  endpoints: (build) => ({
    getInvoice: build.query<api.RedactedInvoiceResponse | undefined, { invoiceId: string }>({
      async queryFn(params, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.getInvoice<api.InvoiceAggregateResponse>(params.invoiceId);
        return { data };
      },
    }),
    getAllInvoices: build.query<api.IPagedResults<api.RedactedInvoiceResponse>, api.InvoiceFilterParams>({
      async queryFn(params, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.getAllInvoices<api.RedactedInvoiceResponse>(params);
        return { data };
      },
      providesTags: ['Invoices'],
    }),
    getAllInvoiceLineItems: build.query<api.IPagedResults<api.RedactedInvoiceLineItemResponse>, api.InvoiceLineItemFilterParams>({
      async queryFn(params, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.getAllInvoiceLineItems<api.RedactedInvoiceLineItemResponse>(params);
        return { data };
      },
      providesTags: (result, _error, { vendorId, batchTransferId }) => [{ type: 'InvoiceLineItems', vendorId, batchTransferId, ...result }],
    }),
    getAllTimeInvoiceTotals: build.query<api.VendorBusinessTotalsResponse, { vendorId: api.UUID }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.getVendorAllTimeInvoiceTotals<api.VendorBusinessTotalsResponse>(vendorId);
        return { data };
      },
      providesTags: ['AllTimeInvoiceTotals']
    }),
    getAllInvoiceAdjustments: build.query<api.IPagedResults<api.RedactedInvoiceAdjustmentResponse>, api.InvoiceAdjustmentFilterParams>({
      async queryFn(params, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.getAllAdjustments<api.RedactedInvoiceAdjustmentResponse>(params);
        return { data };
      },
      providesTags: (result, _error, { vendorId, batchTransferId }) => [{ type: 'InvoiceAdjustments', vendorId, batchTransferId, ...result }],
    }),
    updateVendorClientData: build.mutation<api.VendorExternalClientDataResponse | undefined, { partnerId: number; externalClientId: string; clientName: string }>({
      async queryFn({ partnerId, externalClientId, clientName }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.updateVendorExternalClientData(partnerId, externalClientId, { clientName });
        return { data };
      },
      invalidatesTags: ['Invoices'],
    }),
    previewInstantPayout: build.query<api.AcceleratedPayoutResponse, { invoiceId: api.UUID }>({
      async queryFn({ invoiceId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewInstantPayout(invoiceId);
        return { data };
      },
    }),
    confirmInstantPayout: build.mutation<api.AcceleratedPayoutResponse, { invoiceId: api.UUID }>({
      async queryFn({ invoiceId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmInstantPayout(invoiceId);
        return { data };
      },
      invalidatesTags: ['Invoices', 'BulkAcceleratedPayout', 'SelectiveAcceleratedPayout', 'AllTimeInvoiceTotals'],
    }),
    previewBulkInstantPayout: build.query<api.BulkAcceleratedPayoutResponse, { vendorId: api.UUID }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewBulkInstantPayout(vendorId);
        return { data };
      },
      providesTags: ['BulkAcceleratedPayout'],
    }),
    confirmBulkInstantPayout: build.mutation<api.BulkAcceleratedPayoutResponse, { vendorId: api.UUID }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmBulkInstantPayout(vendorId);

        return { data };
      },
      invalidatesTags: ['Invoices', 'BulkAcceleratedPayout', 'AllTimeInvoiceTotals'],
    }),
    previewSelectiveInstantPayout: build.query<api.SelectiveAcceleratedPayoutResponse, api.SelectiveAcceleratedPayoutRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewSelectiveInstantPayout(vendorId, request);
        return { data };
      },
      providesTags: ['SelectiveAcceleratedPayout'],
    }),
    confirmSelectiveInstantPayout: build.mutation<api.SelectiveAcceleratedPayoutResponse, api.SelectiveAcceleratedPayoutRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmSelectiveInstantPayout(vendorId, request);

        return { data };
      },
      invalidatesTags: ['Invoices', 'SelectiveAcceleratedPayout', 'AllTimeInvoiceTotals'],
    }),
    previewOnDemandPayout: build.query<api.AcceleratedPayoutResponse, { invoiceId: api.UUID }>({
      async queryFn({ invoiceId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewOnDemandPayout(invoiceId);
        return { data };
      },
    }),
    confirmOnDemandPayout: build.mutation<api.AcceleratedPayoutResponse, api.ConfirmOnDemandPayoutRequest & { invoiceId: api.UUID }>({
      async queryFn({ invoiceId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmOnDemandPayout(invoiceId, request);
        return { data };
      },
      invalidatesTags: ['Invoices', 'AllTimeInvoiceTotals'],
    }),
    previewBulkOnDemandPayout: build.query<api.BulkAcceleratedPayoutResponse, { vendorId: api.UUID }>({
      async queryFn({ vendorId }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewBulkOnDemandPayout(vendorId);
        return { data };
      },
      providesTags: ['BulkAcceleratedPayout'],
    }),
    confirmBulkOnDemandPayout: build.mutation<api.BulkAcceleratedPayoutResponse, api.ConfirmOnDemandPayoutRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmBulkOnDemandPayout(vendorId, request);

        return { data };
      },
      invalidatesTags: ['Invoices', 'BulkAcceleratedPayout', 'AllTimeInvoiceTotals'],
    }),
    previewSelectiveOnDemandPayout: build.query<api.SelectiveAcceleratedPayoutResponse, api.SelectiveAcceleratedPayoutRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.previewSelectiveOnDemandPayout(vendorId, request);
        return { data };
      },
      providesTags: ['SelectiveAcceleratedPayout'],
    }),
    confirmSelectiveOnDemandPayout: build.mutation<api.SelectiveAcceleratedPayoutResponse, api.ConfirmSelectiveOnDemandPayoutRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...request }, { dispatch }) {
        const externalInvoiceApi = useInvoiceApi(dispatch);
        const data = await externalInvoiceApi.confirmSelectiveOnDemandPayout(vendorId, request);

        return { data };
      },
      invalidatesTags: ['Invoices', 'SelectiveAcceleratedPayout', 'AllTimeInvoiceTotals'],
    }),
    uploadInvoiceDocument: build.mutation<api.RedactedInvoiceDocumentResponse, {
      invoiceId: string,
      file: File,
    }>({
      query({ invoiceId, file }) {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return {
          url: `/invoices/${invoiceId}/documents`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Invoices'],
    }),
  }),
});

export const {
  useConfirmBulkInstantPayoutMutation,
  useConfirmBulkOnDemandPayoutMutation,
  useConfirmInstantPayoutMutation,
  useConfirmOnDemandPayoutMutation,
  useConfirmSelectiveInstantPayoutMutation,
  useConfirmSelectiveOnDemandPayoutMutation,
  useGetAllInvoiceAdjustmentsQuery,
  useGetAllInvoiceLineItemsQuery,
  useGetAllInvoicesQuery,
  useGetAllTimeInvoiceTotalsQuery,
  useGetInvoiceQuery,
  useLazyGetAllInvoiceAdjustmentsQuery,
  useLazyGetAllInvoicesQuery,
  useLazyPreviewBulkOnDemandPayoutQuery,
  useLazyPreviewSelectiveOnDemandPayoutQuery,
  usePreviewBulkInstantPayoutQuery,
  usePreviewBulkOnDemandPayoutQuery,
  usePreviewInstantPayoutQuery,
  usePreviewOnDemandPayoutQuery,
  usePreviewSelectiveInstantPayoutQuery,
  usePreviewSelectiveOnDemandPayoutQuery,
  useUpdateVendorClientDataMutation,
  useUploadInvoiceDocumentMutation,
} = invoicesApi;

export const exportInvoices = async (filters: api.ExportInvoiceFilterParams, exportFormat: ExportFormatType = 'vendor'): Promise<BlobPart> => {
  const params = new URLSearchParams();
  params.append('exportFormat', exportFormat);

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoices/csv/export?${params.toString()}`);

  Object.entries(filters).forEach(([k, v]) => {
    if (v) {
      if (Array.isArray(v)) {
        (v as Array<any>).forEach((arrayValue) => {
          url.searchParams.append(k, arrayValue);
        });
      } else {
        url.searchParams.append(k, v.toString());
      }
    }
  });
  const headers = await prepareHeaders(new Headers());
  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
  });

  return await response.blob();
};

export const exportInvoiceLineItems = async (filters: Pick<api.ExportInvoiceLineItemFilterParams, 'vendorId' | 'csvFormat' | 'batchTransferId'>, exportFormat: ExportFormatType = 'vendor'): Promise<BlobPart> => {
  const params = new URLSearchParams();
  params.append('exportFormat', exportFormat);

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoice-line-items/csv/export?${params.toString()}`);

  Object.entries(filters).forEach(([k, v]) => {
    if (v) {
      if (Array.isArray(v)) {
        (v as Array<any>).forEach((arrayValue) => {
          url.searchParams.append(k, arrayValue);
        });
      } else {
        url.searchParams.append(k, v.toString());
      }
    }
  });
  const headers = await prepareHeaders(new Headers());
  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    method: 'GET',
    headers,
  });

  return await response.blob();
};
